import React from 'react';
import './AdminPanel.css';
import CalendarView from './Calendar';

export default function AdminPanel() {

    return (
        <div className="admin-container">
            <div className="two-column">
                <div className="column">
                    <CalendarView details={true} snowschool={true}/>
                </div>

            </div>
        </div>
    )
}