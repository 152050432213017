import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CalendarView from './Calendar';
import SnowSchoolView from './SnowSchoolView';
import AdminPanel from './AdminPanel';
import ReportsPage from './ReportsPage';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<CalendarView details={false}/>}/>
            <Route exact path="/kitchen" element={<CalendarView details={true}/>}/>
            <Route exact path="/snowschool" element={<SnowSchoolView/>}/>
            <Route exact path="/admpanel/" element={<AdminPanel/>}/>
            <Route exact path="/reports/" element={<ReportsPage/>}/>
          </Routes>
        </BrowserRouter>
        
      </header>
    </div>
  );
}

export default App;
